import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/seo';

export default function Example() {
  return (
    <Layout funnelString="contact">
      <Seo title="Contact Us" />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="divide-y-2 divide-gray-200">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 leading-6">
                    Collaborate
                  </h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>support@plainice.com</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="pt-16 mt-16 lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Locations
              </h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 leading-6">
                    Utah
                  </h3>
                  <address className="mt-2 text-base text-gray-500">
                    <p className="m-0">285 N Main St</p>
                    <p className="m-0">Suite 322</p>
                    <p className="m-0">Kaysville, UT 84037</p>
                    <p className="m-0">USA</p>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
